<template>
  <BaseForm>
    <PasswordCreate
      v-model:password="form.password"
      v-model:passwordConfirm="form.passwordConfirmation"
      @complete="completePassword($event)"
      floatingLabels
    />

    <template #submit>
      <BaseButton
        label="stp.createNewPassword.form.label.btn"
        @click="submit"
        :loading="loading"
        fullWidth
        small
      />
    </template>
  </BaseForm>
</template>

<script>
import { reactive, ref } from "vue";
import PasswordCreate from "@shared/FormElements/PasswordCreate.vue";

export default {
  name: "RequestForm",

  components: {
    PasswordCreate,
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    backendValidation: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const form = reactive({
      password: null,
      passwordConfirmation: null,
    });

    const isComplete = ref(false);

    const completePassword = (event) => {
      isComplete.value = event;
    };

    const submit = () => {
      if (!isComplete.value) return;
      emit("submit", form);
    };

    return { submit, completePassword, form };
  },
};
</script>
