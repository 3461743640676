<template>
  <BaseFrontdoor keepOut>
    <div class="flex justify-content-center my-8">
      <div class="shadow-3 border-round p-4">
        <div class="flex flex-wrap justify-content-between align-content-center">
          <BaseHeadline size="1" fake="5" class="text-primary" noMargin>
            {{ $t("stp.createNewPassword.title") }}
          </BaseHeadline>
          <BaseButton
            iconLeft="pi pi-times"
            rounded
            text
            color="gc-color-danger"
            @click="goToStartPage"
          />
        </div>

        <CreateForm
          :loading="isLoading"
          @submit="createNewPassword($event)"
          :backendValidation="backendValidation"
        />
      </div>
    </div>
  </BaseFrontdoor>
</template>

<script>
import CreateForm from "./partials/CreateForm.vue";
import useApi from "@use/useApi";
import { useRoute, useRouter } from "vue-router";
import useNotifications from "@use/useNotifications";

export default {
  name: "Create new password",

  setup() {
    const { showError } = useNotifications();
    const { query } = useRoute();
    const { token } = query;

    const router = useRouter();

    const { isLoading, callApi, backendValidation, error } = useApi({
      module: "usm",
      method: "createNewPassword",
      onSuccess: () => {
        router.push({ name: "Login" });
      },
      onError: () => {
        if (error.value.response.status === 409) {
          showError("stp.createNewPassword.error.label", "stp.createNewPassword.error.sameAsOld");
        }
      },
    });

    const createNewPassword = (values) => {
      callApi({ ...values, token });
    };

    return {
      isLoading,
      createNewPassword,
      backendValidation,
    };
  },

  components: {
    CreateForm,
  },

  methods: {
    async goToStartPage() {
      try {
        await this.$router.push({ name: "StartPage" });
      } catch {
        return;
      }
    },
  },
};
</script>
